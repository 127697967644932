import { useEffect, useState } from "react";
import useCatalogoService from "../../../../hooks/useCatalogoService";
import ILogComponente from "../../../../interfaces/ILogComponente";
import styles from './HistoricoComponente.module.scss';

export interface HistoricoComponenteProps {
    idComponente : number
}

const HistoricoComponente : React.FC<HistoricoComponenteProps> = ({idComponente}) => {
    const catalogoService = useCatalogoService();
    const [logs, setLogs] = useState<ILogComponente[]>([]);

    useEffect(() => {
        catalogoService.getHistoricoComponente(idComponente).then(logs => setLogs(logs));
    }, [catalogoService, idComponente])

    return (
        <ul className={styles.lista}>
            {logs.map(log => 
                <li key={log.id}>{log.data}: {log.nome} - versão {log.versao}</li>
            )}
        </ul>
    );
}

export default HistoricoComponente;
import { Breadcrumb, Col, Container, Row } from "react-dsgov";
import { useNavigate } from "react-router";
import imagemDev from "../../images/dev.png";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import useSubtituloState from "../../state/hooks/useSubtituloState";

const Sobre : React.FC = () => {
    const navigate = useNavigate();

    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Sobre");
    }, [setSubtitulo])

    return (
        <Container>
            <Helmet>
                <title>Sobre o Portal do Conhecimento do Desenvolvedor</title>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item>Sobre o Portal</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <img src={imagemDev} alt="Imagem de um desenvolvedor programando" />
                    <h3>O que é o Portal de Conhecimento do Desenvolvedor?</h3>
                    <p>O Portal do Conhecimento do Desenvolvedor é um portal único para todos os
                        desenvolvedores da Secretaria do Tesouro Nacional, sejam eles centralizados
                        na COSIS ou descentralizados nas áreas.
                    </p>
                    <p>Por meio deste portal, o desenvolvedor será capaz de encontrar componentes,
                        tutoriais, manuais, cursos e todas as informações necessárias para desenvolver 
                        sistemas, rotinas ou componentes.
                    </p>
                    <p>
                        As informações trazidas neste portal são consolidadas de vários sistemas 
                        que possuímos, de modo que se tenha um ponto único para acessá-las.
                    </p>
                    <p>
                        O portal oferece uma navegação intuitiva e um mecanismo de busca, que permite encontrar
                        informações em todo o portal.
                    </p>
                    <p>Clique <a href={process.env.PUBLIC_URL + '/busca-portal-conhecimento.crx'}>aqui</a> para baixar a extensão de busca do Portal do Conhecimento do Desenvolvedore para Chrome ou Edge!</p>
                    <h3>O que podemos encontrar no portal?</h3>
                    <p>Podemos, atualmente, encontrar:</p>
                    <ul>
                        <li><b>Componentes: </b>Módulos autocontidos para usar em sua aplicação.</li>
                        <li><b>Documentações: </b>Tutoriais e textos para auxiliar o desenvolvimento.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Sobre;

import ICategoriaDocumentacao from "../../../interfaces/ICategoriaDocumentacao";
import { Card } from "react-dsgov";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from './CardsCategorias.module.scss'

export interface CardsCategoriasProps {
    categorias : ICategoriaDocumentacao[]
}
const CardsCategorias : React.FC<CardsCategoriasProps> = ({categorias}) => {
    return (
        <div className="mb-5 d-flex mt-1 align-content-start flex-wrap">
            {categorias && categorias.map(categoria => <Card key={categoria.id} className={styles['card-categoria']}>
                <Link to={`/docs/${categoria.codigo}`}> <Card.Content>
                    <div className={styles['icone']} style={{color: categoria.cor}}>
                        <i className={classNames(categoria.icone, "fa-4x")}></i>
                    </div>
                    <div>{categoria.nome}</div>
                </Card.Content></Link>
            </Card>)}
        </div>
    );
}

export default CardsCategorias;
const useGeraLink = () => {
    return (codigo : string, tipo : string) : string => {
        switch(tipo) {
            case "C":
                return `/catalogo-componentes/${codigo}`;
            case "AM":
            case "AU":
            case "AH":
                return `/docs/${codigo}`;
        }
        
        return '#';
    }
}

export default useGeraLink;
import { useMemo } from "react";
import CatalogoService from "../services/CatalogoService";
import { useAuth } from "react-oidc-context";


const useCatalogoService = () => {
    const auth = useAuth();
    console.log(auth);

    return useMemo(() => new CatalogoService(auth), [auth]);
}

export default useCatalogoService;
import { useNavigate, useParams } from "react-router";
import IComponente from "../../../interfaces/IComponente";
import { Avatar, Breadcrumb, Button, Card, Col, Container, Item, List, Row, Tab, Table } from "react-dsgov";
import { useEffect, useState } from "react";
import styles from './DetalheComponente.module.scss';
import classNames from "classnames";
import useCatalogoService from "../../../hooks/useCatalogoService";
import HistoricoComponente from "./HistoricoComponente/HistoricoComponente";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import { Helmet } from "react-helmet";
import useLoadingState from "../../../state/hooks/useLoadingState";
import useSubtituloState from "../../../state/hooks/useSubtituloState";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";


export interface IDetalheComponenteProps {

}
const DetalheComponente: React.FC<IDetalheComponenteProps> = () => {
    const navigate = useNavigate();
    const catalogoService = useCatalogoService();

    const { codigo } = useParams();
    const [componente, setComponente] = useState<IComponente>();

    const [, setLoading] = useLoadingState();
    
    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Catálogo de Componentes");
    }, [setSubtitulo])

    useEffect(() => {
        setLoading(true);
        codigo && catalogoService.getComponenteCodigo(codigo).then((componente) => {
            setComponente(componente);
            window.scrollTo(0, 0);
            setLoading(false);
        })
    }, [codigo, catalogoService, setLoading]);

    useEffect(() => hljs.highlightAll(), [componente])

    return <>
        <Helmet>
            <title>{`${componente?.nome} - Catálogo de Componentes`}</title>
        </Helmet>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item href="#" onClick={() => navigate("/catalogo-componentes")}>Catalogo de Componentes</Breadcrumb.Item>
                <Breadcrumb.Item>{componente?.nome}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className={classNames("flex-row", styles["cabecalho"])} >
                <div className={styles['avatar-componente']}>
                    {componente && <Avatar density="medium" icon={componente.icone} bgColor={componente.cor} iconColor="#FFFFFF" />}
                </div>
                <div>
                    <h2 className={styles["titulo"]}>{componente?.nome}</h2>
                    <h4 className={styles["subtitulo"]}>{componente?.subtitulo}</h4>
                </div>

            </Row>

            {componente && <div className={styles["tecnologias-categorias"]}>
                {componente.tecnologias?.length > 0 && <span className={styles["tecnologias"]}><b>Tecnologia{componente.tecnologias?.length >= 2 ? "s" : ""}: </b> {componente?.tecnologias?.map(t => t.nome).join(", ").replace(/, ([^,]*)$/, " e $1")}</span>}
                {componente.categorias?.length > 0 && <span className={styles["categorias"]}><b>Categoria{componente.categorias?.length >= 2 ? "s" : ""}: </b> {componente?.categorias?.map(t => t.nome).join(", ").replace(/, ([^,]*)$/, " e $1")}</span>}
                {componente.temas?.length > 0 && <span className={styles["temas"]}><b>Tema{componente.temas?.length >= 2 ? "s" : ""}: </b> {componente?.temas?.map(t => t.nome).join(", ").replace(/, ([^,]*)$/, " e $1")}</span>}
            </div>}
            <div className={styles["descricao-breve"]}>
                {componente?.descricaoBreve}
            </div>
            <div className={styles["tags"]}>
                {componente?.tags?.map((tag) => <span key={tag.id}>#{tag.nome}</span>)}
            </div>
            

            <Row>
                <Col>
                    <Tab>
                        <Tab.Content title="Sobre">
                            {componente?.sobre && <div className={styles["conteudo"]} dangerouslySetInnerHTML={{ __html: componente?.sobre }} />}
                        </Tab.Content>
                        {componente?.comoComecar && <Tab.Content title="Como começar">
                            {componente?.comoComecar && <div className={styles["conteudo"]} dangerouslySetInnerHTML={{ __html: componente?.comoComecar }} />}
                        </Tab.Content>}
                        
                        {(componente?.documentacao || componente?.endpoints || (componente?.pluginsApex && componente.pluginsApex.length > 0)) && <Tab.Content title="Documentação">
                            {componente?.documentacao && <div className={styles["conteudo"]} dangerouslySetInnerHTML={{ __html: componente?.documentacao }} />}
                            {componente.pluginsApex && componente.pluginsApex.length > 0 &&
                                componente.pluginsApex.map(pluginApex => 
                                    <div key={pluginApex.nome}>
                                        <h3>Parâmetros do Plug-in <b>{pluginApex.nome}</b></h3>
                                        {pluginApex.descricao && <p dangerouslySetInnerHTML={{__html: pluginApex.descricao}}></p>}

                                        <List>
                                            {pluginApex.atributos?.map((atributo, index) =>
                                                <div key={atributo.id}>
                                                    <Item showDividerAfter>
                                                        <b><i className={classNames(atributo.obrigatorio ? "fas" : "far", "fa-arrow-alt-circle-right", styles["icone-parametro-plugin"])}></i> {index + 1}. {atributo.nome}</b>
                                                    </Item>
                                                    <Item>
                                                        <p><b>Obrigatório: </b> {atributo.obrigatorio ? "Sim" : "Não"}</p>
                                                    </Item>
                                                    {atributo.ajuda && <Item>
                                                        <b>O que é:</b>
                                                        <p dangerouslySetInnerHTML={{ __html: atributo.ajuda }}></p>
                                                    </Item>}
                                                    {atributo.valoresPossiveis.length > 0 && <Item>
                                                        <b>Valores possíveis: </b>
                                                        <Table showPagination={false} showDensityButtons={false} showSearch={false} showPageSelector={false} showSelectedBar={false}>
                                                            <thead>
                                                                <th>Valor</th>
                                                                <th>Ajuda</th>
                                                            </thead>
                                                            <tbody>
                                                                {atributo.valoresPossiveis.map(valorPossivel =>
                                                                    <tr key={valorPossivel.id}>
                                                                        <td>{valorPossivel.nome}</td>
                                                                        <td>{valorPossivel.ajuda}</td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </Table>

                                                    </Item>}
                                                    {atributo.exemplos && <Item>
                                                        <b>Exemplos:</b>
                                                        <p dangerouslySetInnerHTML={{ __html: atributo.exemplos }}></p>
                                                    </Item>}




                                                </div>
                                            )}
                                        </List>
                                    </div>
                                )
                            }
                            {componente.endpoints && componente.endpoints.length > 0 &&
                                <>
                                    <div className={classNames("d-flex", "flex-row-reverse", styles.postmanWrapper)}><a href={componente.urlPostman}><Button primary><i className="fas fa-download"></i>Download arquivo Postman</Button></a></div>
                                    <Tab>
                                        <Tab.Content title="Swagger">
                                            <SwaggerUI 
                                                url={`https://apiapex.tesouro.gov.br/aria/v1/portal-conhecimento/custom/openapi?projeto=${componente.idProjetoAria}`}
                                            />  
                                                
                                              
                                        </Tab.Content>
                                        <Tab.Content title="ARIA API">
                                            {componente.endpoints.map(endpoint =>
                                            <List key={endpoint.id}>
                                                <Item showDividerAfter collapsable initiallyExpanded={false} subItems={<>
                                                    <Item>
                                                        <b>URL: </b> {endpoint.url}
                                                    </Item>
                                                    <Item>
                                                        <b>Público: </b> {endpoint.publico ? "Sim" : "Não"}
                                                    </Item>
                                                    <Item>

                                                        <div dangerouslySetInnerHTML={{ __html: endpoint.comentarios }}></div>
                                                    </Item>
                                                    <Item>  
                                                        {endpoint.parametros && endpoint.parametros.length > 0 && 
                                                        <>
                                                            <h6>Parâmetros:</h6>
                                                            <Table title="Parâmetros" showDensityButtons={false} showPageSelector={false} showPagination={false} showSearch={false} showSelectedBar={false} showTopBar={false}>
                                                                <thead>
                                                                    <th>Nome</th>
                                                                    <th>Descrição</th>
                                                                    <th>Origem</th>
                                                                    <th>Valores Possíveis</th>
                                                                </thead>
                                                                <tbody>
                                                                    {endpoint.parametros.map(parametro => 
                                                                        <tr key={parametro.id}>
                                                                            <td>{parametro.nome}</td>
                                                                            <td>{parametro.descricao}</td>
                                                                            <td>{
                                                                                    {
                                                                                        1: "JSON",
                                                                                        2: "QueryString",
                                                                                        3: "Path"
                                                                                    }[parametro.origem]
                                                                                }</td>
                                                                            <td>{parametro.valoresPossiveis}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </>}
                                                    </Item>
                                                    <Item>  
                                                        {endpoint.respostas && endpoint.respostas.length > 0 && 
                                                        <>
                                                            <h6>Respostas:</h6>
                                                            <Table title="Respostas" showDensityButtons={false} showPageSelector={false} showPagination={false} showSearch={false} showSelectedBar={false} showTopBar={false}>
                                                                <thead>
                                                                    <th>Código</th>
                                                                    <th>Descrição</th>
                                                                    <th>Exemplo</th>
                                                                </thead>
                                                                <tbody>
                                                                    {endpoint.respostas.map(resposta => 
                                                                        <tr key={resposta.id}>
                                                                            <td>{resposta.codigo}</td>
                                                                            <td>{resposta.descricao}</td>
                                                                            <td>{resposta.exemplo && resposta.exemplo.length > 0 && <pre><code>{resposta.exemplo}</code></pre>}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </>}
                                                    </Item>
                                                    <Tab>
                                                        {endpoint.codigoJs && <Tab.Content title="Javascript">
                                                            <pre><code>{endpoint.codigoJs}</code></pre>
                                                        </Tab.Content>}
                                                        {endpoint.codigoJava && <Tab.Content title="Java">
                                                            <pre><code>{endpoint.codigoJava}</code></pre>
                                                        </Tab.Content>}
                                                        {endpoint.codigoPyarialib && <Tab.Content title="Pyarialib">
                                                            <pre><code>{endpoint.codigoPyarialib}</code></pre>
                                                        </Tab.Content>}
                                                        {endpoint.codigoArialibts && <Tab.Content title="Arialibts">
                                                            <pre><code>{endpoint.codigoArialibts}</code></pre>
                                                        </Tab.Content>}
                                                    </Tab>
                                                </>}>
                                                    <b><span className={classNames(styles['metodo'], styles[`metodo-${endpoint.metodo.toLowerCase()}`])}>{endpoint.metodo}</span> {endpoint.nome} v{endpoint.versao}</b>
                                                </Item>
                                                
                                            </List>)}
                                        </Tab.Content>
                                    </Tab>
                                    
                                    
                                </>}
                        </Tab.Content>}
                        {<Tab.Content title="Histórico de Versões">
                            {componente && <HistoricoComponente idComponente={componente.id} />}
                        </Tab.Content>}
                    </Tab>
                </Col>
                <Col md={3}>
                    <Card>
                        <Card.Content>
                            {componente?.linkSite && <div className={styles["info-lateral"]}>
                                <b>Site:</b><br />
                                <a href={componente.linkSite} target="_blank" rel="noreferrer">{componente?.linkSite}</a>
                            </div>}
                            {componente?.linkRepositorio && componente?.linkRepositorio !== "#" && <div className={styles["info-lateral"]}>
                                <b>Código-fonte:</b><br />
                                <a href={componente.linkRepositorio} target="_blank" rel="noreferrer">{componente?.linkRepositorio}</a>
                            </div>}
                            {componente?.versao && <div className={styles["info-lateral"]}>
                                <b>Versão:</b><br />
                                {componente?.versao}
                            </div>}
                            {componente?.licencas && componente?.licencas.length > 0 && <div className={styles["info-lateral"]}>
                                <b>Licença{componente.licencas?.length >= 2 ? "s" : ""}: </b><br />
                                {componente?.licencas?.map((licenca) => <a href={licenca.link} target="_blank" rel="noreferrer" key={licenca.id}>{licenca.sigla}</a>)}
                            </div>}
                        </Card.Content>
                    </Card>
                </Col>
            </Row>



        </Container>
    </>;
}

export default DetalheComponente;
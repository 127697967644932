import { Breadcrumb, Container } from "react-dsgov"
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

const Comunidade : React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Helmet>
                <title>Comunidade</title>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item>Comunidade</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
    );
}

export default Comunidade;
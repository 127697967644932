import { Breadcrumb, Col, Container, Row } from "react-dsgov";
import { useNavigate } from "react-router";
import CardsCategorias from "./CardsCategorias/CardsCategorias";
import useCatalogoService from "../../hooks/useCatalogoService";
import { useEffect, useState } from "react";
import ICategoriaDocumentacao from "../../interfaces/ICategoriaDocumentacao";
import MenuLateralCategorias from "./MenuLateralCategorias/MenuLateralCategorias";
import styles from "./Documentacoes.module.scss";
import { Helmet } from "react-helmet";
import useSubtituloState from "../../state/hooks/useSubtituloState";

const Documentacoes : React.FC = () => {
    const navigate = useNavigate();
    const [categorias, setCategorias] = useState<ICategoriaDocumentacao[]>([]);
    const catalogoService = useCatalogoService();

    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Documentações");
    }, [setSubtitulo])

    useEffect(() => {
        catalogoService.getCategoriasDocumentacoes().then(categorias => setCategorias(categorias));
    }, [catalogoService]);

    return (
        <Container className={styles.pagina}>
            <Helmet>
                <title>Documentações</title>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item>Documentações</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col md={3}>
                    <MenuLateralCategorias categorias={categorias} />
                </Col>
                <Col>
                    <CardsCategorias categorias={categorias} />
                </Col>
            </Row>
        </Container>
    );
}

export default Documentacoes;
import { Item } from "react-dsgov";
import { Link } from "react-router-dom";
import { IArtigo } from "../../../../interfaces/IRegistrosArtigosCategoria";
import styles from "./ListaArtigos.module.scss"

export interface ListaArtigosProps {
    artigos?: IArtigo[];
    nivel: number;
    codigoCategoria: string;
    codigoArtigoAtual?: string;
    codigoArtigo: string;
    urlAtual?: string;
}

const ListaArtigos: React.FC<ListaArtigosProps> = ({ artigos, nivel, codigoCategoria, codigoArtigo, codigoArtigoAtual, urlAtual }) => {
    return (
        <>
            {artigos && artigos.map(artigo =>
                <Item 
                    subItems={<ListaArtigos 
                        artigos={artigo.filhos} 
                        nivel={nivel + 1} 
                        codigoCategoria={codigoCategoria} 
                        codigoArtigo={codigoArtigo} 
                        codigoArtigoAtual={codigoArtigoAtual}
                        urlAtual={urlAtual}
                        />}
                >
                    <div style={{ marginLeft: `${(nivel - 1) * 10}px` }}>
                        {artigo.estrutural 
                            ? <>{artigo.nome}</>
                            : <Link 
                                to={`/docs/${codigoCategoria}/${codigoArtigo}${artigo.url}`}
                                className={codigoArtigo === codigoArtigoAtual && urlAtual === artigo.url ? styles.atual : ""}
                            >
                                    {artigo.nome}
                                </Link>
                        }
                        
                    </div>
                </Item>
            )}
        </>
    );
}

export default ListaArtigos;
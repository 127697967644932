import { Item, List } from "react-dsgov";
import { IRegistrosArtigosCategoria } from "../../../interfaces/IRegistrosArtigosCategoria";
import ListaArtigos from "./ListaArtigos/ListaArtigos";

export interface MenuLateralCategoriaProps {
    registrosCategoria : IRegistrosArtigosCategoria;
    codigoCategoria : string;
    codigoArtigoAtual?: string;
    urlAtual?: string;
}

const MenuLateralCategoria : React.FC<MenuLateralCategoriaProps> = ({registrosCategoria, codigoCategoria, codigoArtigoAtual, urlAtual}) => {
    return (
        <List>
            {registrosCategoria.registros.map(artigoPortal => 
                <Item showDividerAfter collapsable initiallyExpanded subItems={<ListaArtigos artigos={artigoPortal.artigos} nivel={1} codigoCategoria={codigoCategoria} codigoArtigo={artigoPortal.codigo} codigoArtigoAtual={codigoArtigoAtual} urlAtual={urlAtual} />} key={artigoPortal.id}>{artigoPortal.nome}</Item>    
            )}
        </List>
    )
}

export default MenuLateralCategoria;
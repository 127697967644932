import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Breadcrumb, Col, Container, Row } from "react-dsgov";
import { useEffect, useState } from "react";
import { IRegistrosArtigosCategoria } from "../../../interfaces/IRegistrosArtigosCategoria";
import useCatalogoService from "../../../hooks/useCatalogoService";
import MenuLateralCategoria from "./MenuLateralCategoria";
import IDadosArtigo from "../../../interfaces/IDadosArtigo";
import styles from './CategoriaDoc.module.scss';
import * as cheerio from "cheerio";
import { Helmet } from "react-helmet";
import hljs from 'highlight.js';
import useLoadingState from "../../../state/hooks/useLoadingState";
import useSubtituloState from "../../../state/hooks/useSubtituloState";


const CategoriaDoc : React.FC = () => {
    const {categoria, artigo, url} = useParams();
    const [searchParams, ] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const catalogoService = useCatalogoService();

    const [registrosCategoria, setRegistrosCategoria] = useState<IRegistrosArtigosCategoria>();
    const [dadosArtigo, setDadosArtigo] = useState<IDadosArtigo>();

    const [urlAtual, setUrlAtual] = useState<string>("");

    const [,setLoading] = useLoadingState();

    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Documentações");
    }, [setSubtitulo])


    

    const handlerReactRouter = (e : any) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        
        navigate(targetLink.getAttribute('href')); 
      };

    useEffect(() => {
        if(categoria) {
            setLoading(true);
            catalogoService.getRegistrosArtigosCategoria(categoria).then(registrosCategoria => {
                setRegistrosCategoria(registrosCategoria);
                setLoading(false);
            });
            
        }
    }, [catalogoService, categoria, setLoading]);


    useEffect(() => hljs.highlightAll(), [dadosArtigo])

    useEffect(() => {
        // Substitui os links internos
        const substituirLinks = async (dadosArtigoObtido : IDadosArtigo) => {
            return new Promise<string>(async (resolve) => {
                if(dadosArtigoObtido.descricao) {
                    const $ = cheerio.load(dadosArtigoObtido.descricao);
                    const idsArtigos: number[] = [];
        
                    // Obtém os links
                    const links = $(".referencia-artigo-interno");
        
                    // Percorre os links
                    links.each((index, element) => {
                        const linkHref = $(element).attr('href');
                        if (linkHref) {
                            const regex = /#marcador-artigo-(\d+)/;
                            const match = linkHref.match(regex);
                            
                            if (match) {
                                const idArtigo = parseInt(match[1]);
                                idsArtigos.push(idArtigo);
                            }
                        }
                    });
        
                    const linksAmigaveis = await catalogoService.getLinksAmigaveisArtigos(idsArtigos);
        
                    // Percorre os links novamente, e substitui os links
                    links.each((index, element) => {
                        const linkHref = $(element).attr('href');
                        if (linkHref) {
                            const regex = /#marcador-artigo-(\d+)(?::(\d+))?/;
                            const match = linkHref.match(regex);
        
                            if (match) {
                                const idArtigo = match[1];
                                const marcador = match[2];
                                const linkAmigavel = linksAmigaveis[idArtigo];
        
                                $(element).attr('href', linkAmigavel + (marcador !== undefined ? `?marcador=${marcador}` : ""));
                            }
                        }
                    });

                    // Percorre os videos, procurando por barras duplas
                    const videos = $("source");

                    videos.each(((index, element) => {
                        const link = $(element).attr('src');
                        if(link) {
                            const novoLink = link.replace(/\/+/g, '/').replace("https:/", "https://").replace("http:/", "http://");
                            $(element).attr('src', novoLink);
                        }                        
                        
                    }))

                    const imagens = $("img");

                    // Substitui os sistemas.tesouro.gov.br para sisweb.
                    imagens.each((index, element) => {
                        const src = $(element).attr('src');

                        if(src) {
                            const novoSrc = src.replace('sistemas.tesouro.gov.br', 'sisweb.tesouro.gov.br');
                            $(element).attr('src', novoSrc);
                        }
                        
                    });

        
                    resolve($.html());
                } else {
                    resolve("");
                }

                
            });
        }


        if(url && artigo && categoria) {
            const marcador = searchParams.get("marcador");

            if(!marcador) {
                window.scrollTo(0, 0);
            }
            
            const urlArtigo = location.pathname.substring(location.pathname.indexOf(`${categoria}/${artigo}`) + `${categoria}/${artigo}`.length);

            setUrlAtual(urlArtigo);
            setLoading(true);
            catalogoService.getArtigo(artigo, categoria, urlArtigo).then(async dadosArtigoObtido => {
                dadosArtigoObtido.descricao = await substituirLinks(dadosArtigoObtido);

                setDadosArtigo(dadosArtigoObtido)  
                setLoading(false);   
                
                // Scroll até o elemento do marcador
                if(marcador) {
                    const element = document.getElementById(`marcador-conteudo-${marcador}`);
                    if(element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    } 
                }
                               
            });
            
        }


        
    }, [catalogoService, location, categoria, artigo, url, searchParams, setLoading])
    
    return (
        <Container>
            <Helmet>
                <title>{dadosArtigo?.nome || registrosCategoria?.nomeCategoria || "Documentações"}</title>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                <Breadcrumb.Item href="#" onClick={() => navigate("/docs")}>Documentações</Breadcrumb.Item>
                {(url && dadosArtigo) 
                    ? <>
                        <Breadcrumb.Item href="#" onClick={() => navigate(`/docs/${categoria}`)}>{registrosCategoria?.nomeCategoria}</Breadcrumb.Item>
                        <Breadcrumb.Item>{dadosArtigo.nome}</Breadcrumb.Item>
                    </>
                    : <Breadcrumb.Item>{registrosCategoria?.nomeCategoria}</Breadcrumb.Item>
                }
            </Breadcrumb>
            <Row>
                <Col md={3}>
                    {registrosCategoria && categoria && <MenuLateralCategoria registrosCategoria={registrosCategoria} codigoCategoria={categoria} codigoArtigoAtual={artigo} urlAtual={urlAtual} />}
                </Col>
                <Col>
                    {!url && registrosCategoria && registrosCategoria.descricaoCategoria &&  <><h2>{registrosCategoria?.nomeCategoria}</h2><div dangerouslySetInnerHTML={{__html: registrosCategoria.descricaoCategoria}}></div></>}
                    {url && dadosArtigo && <>
                        <h2>{dadosArtigo.nome}</h2>
                        <div className={styles.texto} onClick={handlerReactRouter} dangerouslySetInnerHTML={{__html: dadosArtigo.descricao}}></div>
                    </>}
                </Col>
            </Row>
        </Container>
    )
}

export default CategoriaDoc;

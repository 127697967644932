import { Breadcrumb, Button, Checkbox, Col, Container, Item, List, Loading, Row } from "react-dsgov";
import CardComponente from "./CardComponente/CardComponente";
import { useEffect, useState } from "react";
import IComponente, { ICategoria, ITag, ITecnologia } from "../../interfaces/IComponente";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import useCatalogoService from "../../hooks/useCatalogoService";
import { Helmet } from 'react-helmet';
import useLoadingState from "../../state/hooks/useLoadingState";
import useSubtituloState from "../../state/hooks/useSubtituloState";
import ITema from "../../interfaces/ITema";

const CatalogoComponentes = () => {
    const navigate = useNavigate();
    const catalogoService = useCatalogoService();

    const [,setSubtitulo] = useSubtituloState();

    useEffect(() => {
        setSubtitulo("Catálogo de Componentes");
    }, [setSubtitulo])

    // Lista de componentes
    const [componentes, setComponentes] = useState<IComponente[]>([]);

    // Filtros
    const [tecnologias, setTecnologias] = useState<ITecnologia[]>([]);
    const [categorias, setCategorias] = useState<ICategoria[]>([]);
    const [tags, setTags] = useState<ITag[]>([]);
    const [temas, setTemas] = useState<ITema[]>([]);

    // Paginação
    const [pagina, setPagina] = useState<number>(1);
    const [carregouTodos, setCarregouTodos] = useState<boolean>(false);

    // Carregando
    const [carregando, setCarregando] = useState<boolean>(false);

    const [, setLoading] = useLoadingState();

    // Atualizar filtro de tecnologia
    const atualizarTecnologia = (id : number, selecionado : boolean) => {
        setTecnologias((tecnologiasOld) => {
            const novaListaTecnologias = tecnologiasOld?.map(tecnologia => {
                if (tecnologia.id === id) {
                    return { ...tecnologia, selecionado: selecionado };
                }
                return tecnologia;
            });
            return novaListaTecnologias;
        })
    };

    // Atualizar filtro de categoria
    const atualizarCategoria = (id : number, selecionado : boolean) => {
        setCategorias((categoriasOld) => {
            const novaListaCategorias = categoriasOld?.map(categoria => {
                if (categoria.id === id) {
                    return { ...categoria, selecionado: selecionado };
                }
                return categoria;
            });
            return novaListaCategorias;
        })
    };

    // Atualiza o filtro de tags
    const atualizarTag = (id : number, selecionado : boolean) => {
        setTags((tagsOld) => {
            const novaListaTags = tagsOld?.map(tag => {
                if (tag.id === id) {
                    return { ...tag, selecionado: selecionado };
                }
                return tag;
            });
            return novaListaTags;
        })
    };

    // Atualizar filtro de tema
    const atualizarTema = (id : number, selecionado : boolean) => {
        setTemas((temasOld) => {
            const novaListaTemas = temasOld?.map(tema => {
                if (tema.id === id) {
                    return { ...tema, selecionado: selecionado };
                }
                return tema;
            });
            return novaListaTemas;
        })
    };

 

    // Carregar mais componentes, para o infinite scroll
    const carregarMais = () => {
        
        if(!carregando) {
            catalogoService.getComponentes(categorias, tecnologias, tags, temas, pagina + 1).then(novosComponentes => {
                setCarregando(true);
                setLoading(true);
    
                if(novosComponentes.length === 0) {
                    setCarregouTodos(true);
                    setCarregando(false);
                    setLoading(false);
                } else {
                    setPagina((oldPagina) => {
                        const novaPagina = oldPagina + 1;
            
                        if(novosComponentes) {
                            setComponentes(oldComponentes => [...oldComponentes, ...novosComponentes]);
                            setCarregando(false);
                            setLoading(false);
                        }                                    
                        return novaPagina;
                        
                    })
                }
    
            });
        }
        
    }


    useEffect(() => {
        setCarregando(true);
        catalogoService.getComponentes(categorias, tecnologias, tags, temas).then(componentes => {
            setCarregando(false);
            setComponentes(componentes);
            setPagina(1);
        });
    }, [tecnologias, categorias, tags, temas, catalogoService]);

    useEffect(() => {
        

        catalogoService.getTecnologias().then(tecnologias => {
            setTecnologias(tecnologias);
        });

        catalogoService.getCategorias().then(categorias => {
            setCategorias(categorias);
        });

        catalogoService.getTags().then(tags => {
            setTags(tags);
        });

        catalogoService.getTemas().then(temas => {
            setTemas(temas);
        });


    }, [catalogoService]);

    return (
        <>
            <Helmet>
                <title>Catálogo de Componentes</title>
            </Helmet>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item home href="#" onClick={() => navigate("/")}></Breadcrumb.Item>
                    <Breadcrumb.Item>Catalogo de Componentes</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col md={3}>
                        <List title="Filtros">
                            <Item showDividerAfter collapsable initiallyExpanded
                                subItems={<>
                                    {tecnologias?.map((tecnologia, index) =>
                                        <Item key={tecnologia.id}>
                                            <Checkbox 
                                                id={`checkbox-tecnologia-${tecnologia.id}`} 
                                                label={tecnologia.nome} 
                                                value="checkbox-01" 
                                                name="radio-items"  
                                                checked={tecnologia.selecionado}
                                                onChange={(event) => atualizarTecnologia(tecnologia.id, (event.target as any).checked)}
                                            />
                                        </Item>
                                    )}
                                </>}>
                                Tecnologias
                            </Item>
                            <Item showDividerAfter collapsable initiallyExpanded
                                subItems={<>
                                    {categorias?.map((categoria, index) =>
                                        <Item key={categoria.id}>
                                            <Checkbox 
                                                id={`checkbox-categoria-${categoria.id}`} 
                                                label={categoria.nome} 
                                                value="checkbox-01" 
                                                name="radio-items"
                                                checked={categoria.selecionado} 
                                                onChange={(event) => atualizarCategoria(categoria.id, (event.target as any).checked)}
                                            />
                                        </Item>
                                    )}
                                </>}>
                                Categorias
                            </Item>
                            <Item showDividerAfter collapsable initiallyExpanded
                                subItems={<>
                                    {temas?.map((tema, index) =>
                                        <Item key={tema.id}>
                                            <Checkbox 
                                                id={`checkbox-tema-${tema.id}`} 
                                                label={tema.nome} 
                                                value="checkbox-01" 
                                                name="radio-items"
                                                checked={tema.selecionado} 
                                                onChange={(event) => atualizarTema(tema.id, (event.target as any).checked)}
                                            />
                                        </Item>
                                    )}
                                </>}>
                                Temas de Interesse
                            </Item>
                            <Item showDividerAfter collapsable initiallyExpanded
                                subItems={<>
                                    {tags?.map((tag, index) =>
                                        <Item key={tag.id}>
                                            <Checkbox 
                                                id={`checkbox-tag-${tag.id}`} 
                                                label={`#${tag.nome}`} 
                                                value="checkbox-01" 
                                                name="radio-items" 
                                                checked={tag.selecionado} 
                                                onChange={(event) => atualizarTag(tag.id, (event.target as any).checked)}
                                                />
                                        </Item>
                                    )}
                                </>}>
                                Tags
                            </Item>
                        </List>
                    </Col>
                    <Col>
                        
                        <InfiniteScroll
                                    dataLength={componentes.length}
                                    next={carregarMais}
                                    hasMore={!carregouTodos && (componentes.length%15 === 0)}
                                    loader={<>{!carregando && <div className="d-flex justify-content-center"><Button primary onClick={carregarMais}>Carregar mais</Button></div>}</>}
                                    //endMessage={<p>Todos os componentes já foram carregados.</p>}
                                >
                            <div className="mb-5 d-flex mt-1 align-content-start justify-content-center flex-wrap">
                                {componentes?.map((componente) => 
                                    <CardComponente key={componente.id} componente={componente} />
                                )}
                                
                            </div>
                        </InfiniteScroll>
                        {carregando && <Loading />}
                    </Col>
                </Row>
            </Container>

        </>
        
    )
}

export default CatalogoComponentes;
import { Avatar, Button, Card } from "react-dsgov";
import { useNavigate } from 'react-router-dom';
import IComponente from "../../../interfaces/IComponente";
import styles from './CardComponente.module.scss';
import Util from "../../../services/Util";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";

export interface ICardComponenteProps {
    componente : IComponente
}

const CardComponente : React.FC<ICardComponenteProps> = ({componente}) => {
    const navigate = useNavigate();

    const [linkComponente,] = useState(`/catalogo-componentes/${componente.codigo}`);

    const copiarLink = (componente : IComponente, link : string) => {
        navigator.clipboard.writeText(`${window.location.origin}${link}`);
        toast.info(`Endereço do componente "${componente.nome}" copiado para a Área de Trabalho`);
    }

    return (
        <Card className={styles["card-componente"]} key={componente.id} hover>
            <Card.Header 
                cardTitle={componente.nome} 
                cardSubtitle={componente.subtitulo}
                cardCustomAvatar={<Avatar icon={componente.icone} bgColor={componente.cor} iconColor="#FFFFFF" />}
            />
            <Card.Content>
                <div className={styles["tecnologias"]}>
                    <b>Tecnologia{componente?.tecnologias?.length >= 2 ? "s" : ""}: </b> {componente.tecnologias.map(t => t.nome).join(", ").replace(/, ([^,]*)$/, " e $1")}
                </div>
                <div className={styles["descricao-breve"]}>
                    {componente.descricaoBreve}
                </div>
                <div className={styles["tags"]}>
                    {componente?.tags?.map((tag) => <span key={tag.id}>#{tag.nome}</span>)}
                </div>
                
            </Card.Content>
            <Card.Footer>
                <div className="d-flex">
                    <div>
                        <Button onClick={() => navigate(linkComponente)} primary>Detalhes</Button>
                    </div>
                    <div className="ml-auto">
                        {/*<Button circle aria-label="Ícone ilustrativo" icon="fas fa-heart" />*/}
                        <Button onClick={() => copiarLink(componente, linkComponente)} circle aria-label="Ícone ilustrativo 3" icon="fas fa-share-alt" />
                    </div>
                </div>
                
            </Card.Footer>
        </Card> 
    );
}

export default CardComponente;
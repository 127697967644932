import { Item, List } from "react-dsgov";
import { Link } from "react-router-dom";
import ICategoriaDocumentacao from "../../../interfaces/ICategoriaDocumentacao";

export interface MenuLateralCategoriasProps {
    categorias : ICategoriaDocumentacao[]
}

const MenuLateralCategorias : React.FC<MenuLateralCategoriasProps> = ({categorias}) => {
    return (
        <List>
            {categorias && categorias.map(categoria => 
                <Item showDividerAfter collapsable initiallyExpanded
                    key={categoria.id}
                    subItems={<>{categoria.artigos.map(artigo =>
                        <Item key={artigo.id}><Link to={`/docs/${artigo.codigo}`}>{artigo.nome}</Link></Item>
                    )}</>}
                ><b>{categoria.nome}</b></Item>    
            )}
        </List>
    )
}

export default MenuLateralCategorias;
import { atom } from "recoil";

export const loading = atom<boolean>({
    key: 'filtros',
    default: false
})

export const subtitulo = atom<string>({
    key: "subtitulo",
    default: ""
});